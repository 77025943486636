
  .exp-button {
    height: 40px;
    line-height: 1.1rem !important;

    @apply rounded-md font-semibold text-sm select-none;
    @apply flex items-center justify-center;
    @apply px-6 outline-none;
    @apply transition-all;
  }

  .exp-button:not(.uppercase):not(.ignore-capitalize) > span,
  .exp-button:not(.uppercase):not(.ignore-capitalize) > div {
    @apply lowercase first-letter:capitalize;
  }

  .exp-button-small {
    height: 32px;

    @apply px-4;
  }

  .exp-button-xs {
    height: 26px;
    @apply px-1;
  }

  .exp-button-xs > span{
    @apply text-[13px];
  }

  .exp-button-lg {
    @apply h-12 px-5 normal-case font-bold;
  }

  .exp-button-icon-right {
    @apply pr-2;
  }
  .exp-button-icon-right > svg {
    @apply ml-2 text-xl;
  }

  .exp-button-icon-left {
    @apply pl-3.5;
  }
  .exp-button-icon-left > svg {
    @apply mr-1.5 text-xl;
  }

  .icon-type-button {
    @apply w-10 px-0;
  }

  .icon-type-button > svg {
    @apply text-2xl;
  }

  .icon-type-button.exp-button-icon-left > svg,
  .icon-type-button.exp-button-icon-right > svg {
    @apply m-0;
  }

  /* WHITE */
  .exp-button-white {
    @apply bg-white border border-[#DDE8EC];
  }

  .exp-button-white:hover {
    @apply ring-1 ring-opacity-20 ring-[#233841];
  }

  .exp-button-gray:disabled,
  .exp-button-gray[disabled] {
    @apply opacity-50;
  }

  .exp-button-red,
  .exp-button-green {
    @apply text-white hover:bg-opacity-[0.88];
  }

  .exp-button-red {
    @apply bg-v2red;
  }

  .exp-button-green {
    @apply bg-v2green;
  }