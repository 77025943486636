@import url('https://rsms.me/inter/inter.css');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

@tailwind base;

@layer base {
  :root {
    --portal-black-color: 19, 29, 38;
    --portal-brand-color: 19, 29, 38;
    --portal-buttonText-color: 255, 255, 255;
    --portal-topBarText-color: 255, 255, 255;
    --portal-topBar-color: 19, 29, 38;
    --portal-alert-color: 236, 102, 102;
    --portal-accent-color: 75, 180, 180;
  }

  html {
    -webkit-font-smoothing: antialiased; /* remains from CssBaseline */
    -moz-osx-font-smoothing: grayscale; /* remains from CssBaseline */
  }

  body {
    font-family: theme('fontFamily.sans');
  }

  svg {
    vertical-align: initial; /* diffeerence in resets. tailwind resets to "middle" */
  }

  p {
    margin-bottom: 13px; /* difference in resets. tailwind resets to "0" */
  }

  ol {
    list-style: decimal; /* difference in resets. tailwind resets to "none" */
  }
  ul {
    list-style: initial; /* difference in resets. tailwind resets to "none" */
  }

  .font-inter,
  .font-inter * {
    font-feature-settings: 'cv02', 'cv03', 'cv04', 'cv06', 'cv09';
  }
}

@tailwind components;
@tailwind utilities;

@layer components {
  .hdot::after {
    content: '.';
    color: theme('colors.primary.base');
  }

  .animate-spin-slow {
    animation: spin 6s linear infinite;
  }
}

/* mui v4 default. default (100%) causes modals to scroll out of viewport if modal is taller than viewport */
.MuiDialog-scrollBody::after {
  height: unset !important;
}

.MuiCheckbox-root {
  @apply text-primary-base;
}

.icon-5 {
  @apply h-5 w-5 flex;
}

.icon-5 > svg {
  @apply text-xl;
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Remove the background color for autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-background-clip: text;
  -webkit-text-fill-color: inherit;
  transition: background-color 5000s ease-in-out 0s;
  box-shadow: inset 0 0 20px 20px transparent;
}
