
.db-card-title {
  @apply text-v2blueGray-0 font-medium text-22 leading-[26px];
  letter-spacing: -0.704px;
}

.db-card.gray .db-card-title,
.db-card.sub .db-card-title {
  @apply text-v2blueGray-900;
}
