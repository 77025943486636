/* Button themes */
.button-primary {
  @apply text-white bg-v2blueGray-800 shadow-ui-kit-base;
  /* @apply text-white bg-v2blueGray-800; */
}

.button-primary:not(.loading):hover {
  @apply bg-v2blueGrayHover-800;
}

.button-primary:not(.loading):disabled {
  @apply text-[#9DB3BF];
}

.button-primaryBordered {
  /* @apply text-white border border-white bg-v2blueGray-800 shadow-ui-kit-base; */
  @apply text-white border border-white bg-v2blueGray-800;
}

.button-primaryBordered:not(.loading):hover {
  @apply bg-v2blueGrayHover-800;
}

.button-secondary {
  @apply text-v2blueGray-800 bg-white hover:bg-v2blueGrayHover-200 border border-solid border-v2blueGray-200;
}

.button-secondary:not(.loading):disabled {
  @apply text-v2blueGray-200;
}

.button-danger {
  /* @apply text-white bg-v2red hover:bg-v2red-500 shadow-ui-kit-base; */
  @apply text-white bg-v2red shadow-ui-kit-base hover:bg-opacity-[0.88];
}

.button-violet {
  @apply text-white bg-v2violet shadow-ui-kit-base hover:bg-opacity-90;
}

.button-green {
  @apply text-white bg-v2green shadow-ui-kit-base hover:bg-opacity-[0.88];
}

.button-orange {
  @apply text-white bg-v2orange shadow-ui-kit-base hover:bg-opacity-90;
}

.button-blue {
  @apply text-white bg-v2blue-600 shadow-ui-kit-base hover:bg-opacity-90;
}

.button-blueBordered {
  @apply text-v2blue-600 border border-v2blue-600/20  hover:bg-v2blue-600/5;
}

.button-info {
  /* @apply text-white bg-v2red hover:bg-v2red-500 shadow-ui-kit-base; */
  @apply text-white bg-v2blue hover:bg-opacity-[.88];
}

.button-whatsApp {
  @apply text-white bg-gradient-to-r from-[#26B43D] to-[#59D265] hover:from-[#26B43D]/80 hover:to-[#59D265]/80;
}

.button-facebook {
  @apply text-white bg-[#1877F2] hover:bg-opacity-80;
}

.button-facebook.disabled {
  @apply bg-opacity-80;
}

/* Button */
.button {
  @apply inline-flex items-center justify-center gap-x-1 h-9 px-[20px] rounded-10 transition-colors;
  @apply font-inter font-normal text-[14px] tracking-[-0.14px];
}

.button:not(:disabled):not(.loading) {
  @apply transition-all duration-200 ease-in-out transform active:scale-[0.98];
}

.button:disabled,
.button.disabled {
  @apply cursor-not-allowed;
}

.button:not(.loading):disabled {
  @apply opacity-50;
}

.button-info:not(.loading):disabled {
  @apply opacity-30;
}

/* Button icon */
.button-icon-left {
  @apply flex-row-reverse pl-2 pr-5;
}

.button-icon-right {
  @apply flex-row pl-5 pr-2;
}

/* Icon */
.icon {
  @apply relative;
}

.icon-left {
  @apply order-1 ml-0;
}

.icon-right {
  @apply order-2 mr-0;
}

.icon-divider {
  @apply absolute top-1/2 left-0 w-[1px] h-4 border-solid border-l border-v2blueGray-700 transform -translate-y-1/2;
}
