.dropdown-dark .agent-section-title {
  @apply text-v2blueGray-500;
}

.dropdown-dark .agent-section-name {
  @apply text-v2blueGray-0;
}

.dropdown-dark .offline .agent-section-name {
  @apply text-v2blueGray-600;
}