.dashboard-kpi-list {
  @apply grid gap-12;
}

.dashboard-kpi-list > *:not(:first-child) {
  position: relative;
}

.dashboard-kpi-list > *:not(:first-child)::before {
  @apply bg-v2blueGray-200;
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: -24px;
}