.db-card,
.db-card-content {
  border-radius: 22px;
}

.db-card {
  @apply flex flex-col
}

.db-card-content {
  padding: 29px 32px;
}

/**GRAY THEME**/
.db-card.gray,
.db-card.sub {
  @apply bg-v2blueGray-100;
}
.db-card.gray .db-card-content,
.db-card.sub  .db-card-content{
  @apply bg-v2blueGray-0;
}
/**BLACK THEME**/
.db-card.black {
  @apply bg-v2blueGray-800 bg-opacity-20;
}
.db-card.black:not(.sub) .db-card-content {
  @apply bg-v2blueGray-800;
}
/**BLACK VIOLET**/
.db-card.violet {
  @apply bg-v2violet bg-opacity-20;
}
.db-card.violet:not(.sub) .db-card-content {
  @apply bg-v2violet;
}
/**BLACK GREEN**/
.db-card.green {
  @apply bg-v2green bg-opacity-20;
}
.db-card.green:not(.sub) .db-card-content {
  @apply bg-v2green;
}
/**BLACK ORANGE**/
.db-card.orange {
  @apply bg-v2orange bg-opacity-20;
}
.db-card.orange:not(.sub) .db-card-content {
  @apply bg-v2orange;
}
/**BLACK BLUE**/
.db-card.blue {
  @apply bg-v2blue-600 bg-opacity-20;
}
.db-card.blue:not(.sub) .db-card-content {
  @apply bg-v2blue-600;
}