.dashboard-kpi-grid {
  @apply grid gap-16;
}

.dashboard-kpi-grid > *:not(:first-child) {
  position: relative;
}

.dashboard-kpi-grid > *:not(:first-child)::before {
  @apply bg-v2blueGray-200 -translate-y-1/2;
  content: "";
  height: 32px;
  width: 1px;
  position: absolute;
  left: -32px;
  top: 50%;
}