.input-sizer {
  position: relative;
  /* border: solid 1px; */
  /* width: 100%; */
}

.input-sizer textarea {
  position: absolute;
  inset: 0;
}

.input-sizer::after,
.input-sizer textarea {
  /* width: 100%; */
  font: inherit;
  /* padding: 0;
  margin: 0; */
  resize: none;
  background: none;
  appearance: none;
  white-space: pre-wrap;
  /* /* border: none; */
}

.input-sizer::after {
  content: attr(data-value) ' ';
  display: block;
  white-space: pre-wrap;
  word-break: break-word;
  visibility: hidden;
}
