.ov-color-picker .react-colorful__saturation-pointer {
  width: 14px;
  height: 14px;
}

.ov-color-picker.react-colorful {
  border-radius: 14px;
  width: 212px;
  height: 248px;
  background: white;
  box-shadow: rgba(35, 56, 65, 0.1) 0 1px 16px;
}

.ov-color-picker .react-colorful__hue-pointer {
  width: 12px;
  height: 12px;
}

.ov-color-picker .react-colorful__hue {
}

.ov-color-picker .react-colorful__last-control {
  margin: 24px 24px 24px 56px;
  border-radius: 6px;
  height: 8px;
}

.ov-color-picker .react-colorful__interactive {
  z-index: 1;
}

.ov-color-picker .react-colorful__hue-pointer .react-colorful__pointer-fill {
  background: white !important;
}
