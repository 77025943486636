.dropdown-menu-button:has(.dropdown-open) {
  z-index: 2;
}

@keyframes ticket-pin-animation {
  0% {
    transform: scale(1);
    opacity: 0.2;
  }
  45% {
    transform: scale(2.5);
    opacity: 0.2;
  }
  50% {
    transform: scale(2.5);
    opacity: 0.2;
  }
  55% {
    transform: scale(2.5);
    opacity: 0.2;
  }
  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}

.ticket-preview-unread-message-pin-background {
  animation: ticket-pin-animation 1.5s ease-in-out infinite;
}

@keyframes ticket-background-animation {
  0% {
    opacity: 0;
  }
  45% {
    opacity: 0.16;
  }
  50% {
    opacity: 0.16;
  }
  55% {
    opacity: 0.16;
  }
  100% {
    opacity: 0;
  }
}

.ticket-preview-unread-message-background {
  background: #007aff !important;
  animation: ticket-background-animation 1.5s ease-in-out infinite;
}
