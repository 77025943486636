

.db-head-desc {
  @apply text-v2blueGray-0 text-opacity-80 font-medium text-sm leading-[22px];
  max-width: 280px;
}

.db-card.gray .db-head-desc,
.db-card.sub .db-head-desc {
  @apply text-v2blueGray-400;
}

